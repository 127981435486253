import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6fc49e02"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "swiper"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["src"];
import { reactive } from 'vue';
import { getBannerList } from '../api/common';
import { onMounted } from 'vue';
export default {
  __name: 'HomeSwiper',
  setup(__props) {
    const state = reactive({
      banner: [] // 轮播图
    });

    /**
     * 获取首页轮播图
     */
    function getBannerSwiper() {
      const param = {
        status: 1,
        type: 'banner'
      };
      getBannerList(param).then(res => {
        const response = res;
        state.banner = response;
      });
    }
    onMounted(() => {
      getBannerSwiper();
    });
    return (_ctx, _cache) => {
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_swipe, {
        autoplay: "3000",
        class: "home-swipe"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.banner, (item, index) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: index
          }, {
            default: _withCtx(() => [_createElementVNode("a", {
              href: item.meta.link
            }, [_createElementVNode("img", {
              src: item.image
            }, null, 8, _hoisted_3)], 8, _hoisted_2)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })]);
    };
  }
};